<template>
	<div>
		<!-- 头部logo -->
		<moveHeadLogo @navigation="navigation"></moveHeadLogo>
		
		<!-- 头部导航 -->
		<moveNavigation :headTitle="headTitle" :headImg="headImg"></moveNavigation>
		
		<div class="aboutUsM">
			<el-row class="aboutUsM-information">
				<el-row class="aboutUsM-title">Who we are</el-row>
				<el-row>
					<el-col :span="24" class="aboutUsM-data">
						<el-row class="aboutUsM-number">{{newList.theaterNum}}</el-row>
						<el-row class="aboutUsM-message">
							Theatres across the country
						</el-row>
						<el-row>
							<el-image class="aboutUsM-chainimg" src="/home/chain.png"></el-image>
						</el-row>
					</el-col>	
					<el-col :span="24" class="aboutUsM-data">
						<el-row class="aboutUsM-number">{{newList.repertoryNum}}</el-row>
						<el-row class="aboutUsM-message">
							Annual performances
						</el-row>
						<el-row>
							<el-image class="aboutUsM-chainimg" src="/home/chain.png"></el-image>
						</el-row>
					</el-col>	
					<el-col :span="24" class="aboutUsM-data">
						<el-row class="aboutUsM-number">{{newList.viewerNum}}</el-row>
						<el-row class="aboutUsM-message">
							Audience
						</el-row>
						<el-row>
							<el-image class="aboutUsM-chainimg" src="/home/chain.png"></el-image>
						</el-row>
					</el-col>	
				</el-row>
			</el-row>
			<el-row class="aboutUsMvalues-message" v-html="newList.bodyContent">
				{{newList.bodyContent}}
			</el-row>
		</div>
		
		<!-- 菜单导航 -->
		<moveMeauDialog :meauValue="navigationvalue" @meauFn="menu"></moveMeauDialog>
		
		<!-- 底部导航 -->
		<moveBottomNavigation></moveBottomNavigation>
	</div>
</template>


<script>
	
// 图片查询接口
import { selectPictureListById , selectContentListById } from '@/api/index.js'

// 头部组件
import moveHeadLogo from '@/components/move/moveHeadLogo.vue'
// 头部导航
import moveNavigation from '@/components/move/moveNavigation.vue'
// 底部导航
import moveBottomNavigation from '@/components/move/moveBottomNavigation.vue'
// 菜单导航
import moveMeauDialog from '@/components/move/moveMeauDialog.vue'
export default 
{
	data()
	{
		return{
			// 头部导航标题
			headTitle:'WHO WE ARE',
			// 头部导航图片
			headImg:'/index/team.png',
			
			// 导航值
			navigationvalue:false,
			
			newList:{}
		}
	},
	components:
	{
		moveHeadLogo,
		moveNavigation,
		moveBottomNavigation,
		moveMeauDialog,
	},
	created()
	{
		this.getList();
	},
	methods:
	{
		// 头部传来的数据
		navigation(v)
		{
			this.navigationvalue=v;
			console.log(this.navigationvalue,'头部传递菜单导航')
		},
		// 遮罩层传回来的数据
		menu(v)
		{
			this.navigationvalue=v;
		},
		getList()
		{
			selectContentListById(
			{
			  chnanelType: 2,
			  isIndex: 0,
			  moduleType: 'MODULE_WHO'
			})
			.then((response)=>
			{
				this.newList=response.data.data[0];
			})
		}
	}
}
</script>

<style>
	.aboutUsM{
		width: 90%;
		margin-left: 5%;
	}
	.aboutUsM-information{
		width: 98%;
		border: solid 1px black;
		position: relative;
		padding-top: 30px;
	}
	.aboutUsM-title{
		width: 50%;
		height: 30px;
		background-color: white;
		position: absolute;
		top: -20px;
		font-size: 5vw;
		left: 25%;
		
		height: 36px;
		font-size: 48px;
		color: #505050;
		line-height:36px;
	}
	
	.aboutUsM-data{
		width: 90%;
		text-align: center;
		background-color: #EEEEEE;
		border-radius: 10px;
		margin-bottom: 50px;
		margin-left: 5%;
		margin-top: 19px;
	}
	.aboutUsM-chainimg{
		width: 128px;
		height: 13px;
		margin-top: 22px;
		margin-bottom:15px;
	}
	.aboutUsM-number{
		margin-top: 40px;
		color: #505050;
		height: 45px;
		font-size: 60px;
		line-height: 45px;
	}
	.aboutUsM-message{
		margin-top: 19px;
		height: 17px;
		font-size: 18px;
		color: #505050;
		line-height: 17px;
	}
	.aboutUsMvalues-message{
		text-align: left;
		line-height: 30px;
		margin-top: 30px;
		display: inline-block;
		text-align: justify;
		text-justify: distribute-all-lines;
	}
	.aboutUsMvalues-message img{
		max-width: 100%;
	}
	.aboutUsMvalues-message video{
		width: 100%;
	}
</style>